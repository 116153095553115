import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { CSVLink, CSVDownload } from "react-csv";
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'
import '../style/gold4.css'
import cincin from '../assets/img/gold4/cincin.svg'
import groom from '../assets/img/groom.png'
import music from '../assets/music/music3.aac'
import logoig from '../assets/img/gold4/logoig.svg'
import logoig1 from '../assets/img/logoig1.svg'
import bunga from '../assets/img/bunga.svg'
import divider from '../assets/img/divider1.svg'
import AOS from 'aos';
import "aos/dist/aos.css";
import { Helm } from '../components/header'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import { gambar } from '../params'
import { cap } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.withfriend = React.createRef()

    this.state = {
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      err: [],
      submitted: '',
      sesi: 1,
      hadir: false
    }
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    AOS.init({
      duration: 1000
    });
    var countDownDate = new Date("11/07/2020").getTime();
    var x = setInterval(() => {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);

  }
  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();

    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/event?action=TEMPLATE&tmeid=MnRrNW1lY2pjcWNvN3E1bzk1YmthZ2dpNjMgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com
    `, '_blank')
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "ririn-rozaq", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }

  render() {
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''
    let { days, hours, minutes, seconds, hide, hadir, err, submitted, sesi } = this.state
    let slide = ["	https://i.ibb.co/jkk9j88/MG-9656-2.jpg				",
      "	https://i.ibb.co/SK4pjFd/EV0-G1543-2.jpg				",
      "	https://i.ibb.co/w6X1qZd/EV0G1557.jpg				",
      "	https://i.ibb.co/gR0nm8z/EV0-G1590-2.jpg				",
      "	https://i.ibb.co/hYL3Hn6/EV0G1614.jpg				",
      "	https://i.ibb.co/FxL5dH6/KURN6697.jpg				",
      "	https://i.ibb.co/bPvH5nL/KURN6700-2.jpg				",
      "	https://i.ibb.co/2Kxv5bJ/KURN6706.jpg				",
      "	https://i.ibb.co/C9mLHZX/KURN6752-2.jpg				",
      "https://i.ibb.co/QQFpZXx/MG-9692.jpg"

    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95,
        'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'))
    })
    return (
      <>
        <Helm
          title='Undanganku - Ririn & Rozaq'
          desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
          logo={logo}
        />
        <div style={{ width: '100vw', overflow: 'hidden' }} id='gold4'>


          <div className="jumbotrongold4 jumbotron-fluid mb-0" id='aftermodal' style={{
            backgroundImage: `url(${hide ? gambar('https://i.ibb.co/HK5VMwB/Modal-Dalam.jpg', 95,
              'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1200&h=900') : gambar('https://i.ibb.co/n37QZSG/Modal-Depan.jpg', 95,
                'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1200&h=900')})`
          }}>
            <div className="container">
              <Item><p className='poppins'>The Wedding of</p>
              </Item>
              <div style={{
                fontSize: '3rem',
                fontFamily: 'Sacramento, cursive',
                lineHeight: 1
              }}
              >
                <Item>

                  Ririn
            </Item>
                <Item>
                  &
            </Item>
                <Item>
                  Rozaq
            </Item>

                <Item>
                  {query ? (
                    <h2 className={`col-md-4 poppins text-center pt-5 pt-sm-3`}
                      style={{ fontSize: '16px' }}> Kepada Bapak/Ibu/Saudara/i :<br /><br/>
                      <span className='pt-4' style={{fontSize:'22px'}}>
                        {query}
                      </span>
                    </h2>

                  ) : false}</Item>
              </div>
              <div style={{
                marginTop: '75px'
              }}>
                <Item>
                  <div className='tombol btn p-0'
                    onClick={() => {
                      this.play()
                    }}
                    style={{
                    }}>Open Invitation</div>
                </Item>
              </div>

            </div>

          </div>

          <div className={hide ? 'd-none' : 'd-block'} id='open'>
            <div id='top'></div>
            <Header />
            <Container
              fluid
              style={{
                paddingTop: '5vw',
                paddingBottom: '5vw',
                fontStyle: 'italic',
                fontWeight: '600',
                color: '#7f8aa9ff',
              }}
            >
              <Item>
                <p className='text-center col-md-8 quotes' data-aos="fade-left"
                  data-aos-duration="2000">
                  “And of His signs is that He created for you from yourselves mates that youmay
                  find tranquility in them; and He placed between you affection and mercy.Indeed
            in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
            </p>
              </Item>
            </Container>

            <Container
              fluid
              style={{
                paddingTop: '5vw',
                paddingBottom: '5vw',
                backgroundColor: '#7f8aa9ff',
                color: '#fff '
              }}
              className='text-center' id='ucapan'
            >
              <Item >
                <img src={cincin}
                  className='text-center cincin m-4' data-aos="fade-left"
                  data-aos-duration="2000" />
              </Item>
              <div >
                <Mempelai>Faridhatul Qomariyah</Mempelai>
                <div className='f-larger'>



                  <Item>Putri dari :</Item>
                  <Item><p className='text-center' data-aos="fade-left"
                    data-aos-duration="2000" style={{ fontStyle: 'italic' }}>


                    Bapak H.M Nasaruddin Ismail <br />
dan<br />
Ibu Hj Sri Hartati
</p>
                  </Item>
                </div>
              </div>
              <div>
                <Mempelai>&</Mempelai></div>
              <div data-aos="flip-left"
                data-aos-duration="2000">

                <Mempelai>Moch Auliya Rozaq</Mempelai>
                <div className='f-larger'>
                  <Item>Putra dari:</Item>
                  <Item><p className='text-center' data-aos="fade-left"
                    data-aos-duration="2000" style={{ fontStyle: 'italic' }}>
                    Bapak H. Moch. Solichun Sofada (Alm)<br />
                                                dan<br />
                                                Ibu Hj Ratri Sunarti
                                                </p>
                  </Item>
                </div>
              </div>
            </Container>



            <Container
              fluid
              style={{
                paddingTop: '5vw',
                paddingBottom: 0,
                color: '#7f8aa9ff',
                maxWidth: '100vw',
                backgroundColor: '#E1E7F6'
              }}
              className='text-center w-100'
            >

              <Item><img className='text-center' data-aos="fade-left"
                data-aos-duration="2000" src={bunga} style={{ width: '150px', marginTop: '20px' }} /></Item>
              <Item><p className='savethedate' style={{ fontSize: '1rem', color: '#6e6e6eff' }} data-aos="fade-left"
                data-aos-duration="2000">
                Save The Date
            </p></Item>
              <div data-aos="fade-left"
                data-aos-duration="2000">



                <p style={{ fontSize: '3rem', fontFamily: 'Sacramento, cursive', }}>


                  Resepsi</p>
                <div style={{ fontSize: '16px' }}>
                  <Item data-aos="fade-left"
                    data-aos-duration="2000"><p style={{ color: '#6e6e6eff', fontWeight: '600' }}>
                      Sabtu, 07 November 2020
                </p></Item>
                  <Item data-aos="fade-left"
                    data-aos-duration="2000"><p style={{ color: '#6e6e6eff' }}>
                      <b>19.30 WIB - 21.00 WIB</b> <br />
                      <b>Gedung Graha YKP</b> <br /><br />
                                            Jl. Medokan Asri Utara I / 39 Surabaya<br />
                                            Rungkut, Kota Surabaya, Jawa Timur, 60293
            </p>
                  </Item>



                  <Item>
                    <Col sm={8}>
                      <Alert variant='danger'>
                        Akad Nikah telah dilaksanakan pada <div className='d-block d-sm-inline'>
                          08 Agustus 2020 di Swiss-Belinn Airport Hotel Surabaya</div>
                      </Alert></Col>
                  </Item>
                  <Item>
                    <Col xs={10} md={6} className='p-3 rounded  s-bold c-main' data-aos="fade-left">
                      <Item>
                        <div className='item'>
                          <Item>
                            <div>
                              {days}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Days
                                        </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {hours}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Hours
                                            </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div >
                              {minutes}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Mins
                                            </span>
                          </Item>
                        </div>
                        <div className='dot' >:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {seconds}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Secs
                                            </span>
                          </Item>
                        </div>
                      </Item>
                    </Col>
                  </Item>

                  <Item ><div className='col-6 col-md-4 p-1 btn'
                    style={{ backgroundColor: '#7f8aa9ff', borderRadius: '10px', color: '#fff' }} onClick={() => { window.open('https://www.google.com/maps/dir//Gedung+Graha+YKP/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x2dd7fa94301d2101:0x5ac8d913c0c265b4?sa=X&ved=2ahUKEwigrZ3jzdTsAhVVb30KHdSEAmEQ9RcwHHoECBYQAw') }}>
                    View on Google Maps
              </div></Item>
                  <Item ><div onClick={() => { this.googleCalendar() }} className='col-6 col-md-4 p-1 m-3 btn'
                  
                    style={{ backgroundColor: '#7f8aa9ff', borderRadius: '10px', color: '#fff' }}>
                    Add to Calendar
              </div></Item>
                </div>
              </div>
              <Item>
                <Col xs={12} md={8} className='px-2'>
                  <Alert variant='warning text-justify' className='f-14  mt-3 text-center'
                    style={{ backgroundColor: 'rgb(225, 231, 246)', border: '2px solid rgb(127, 138, 169)', color: 'rgb(127, 138, 169)', fontSize: '14px' }}>
                    <p ><b>Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala, kami mengundang
                    Bapak/ Ibu / Saudara/i, untuk menghadiri Resepsi Pernikahan kami. Dengan tetap mematuhi protokoler kesehatan
                                            </b>
                      <br /><br />
                                            --
                                            </p>
                    <Item>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/nCYXNPV/14.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p >
                          Selalu memakai masker selama acara berlangsung
                                                </p>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/9gfP42Z/11.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p className='text-center'>
                          Tidak bersalaman, dapat menggantinya dengan salaman jarak jauh
                                                </p>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/s2wYtW9/12.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p className='text-center'>
                          Mencuci tangan sebelum dan sesudah memasuki lokasi acara
                                                </p>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/t8mpzrN/13.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p className='text-center'>
                          Tidak berkerumun, dengan menjaga jarak satu sama lain ± 2 meter
                                                </p>
                      </Col>
                      <Alert variant='info text-center' className='f-14  mt-3'>
                        <p>
                          Harap datang sesuai dengan waktu yang telah ditentukan
                                            </p>
                      </Alert>
                    </Item>
                  </Alert>
                </Col>
              </Item>


              <div className='mbx-1'>
                <Item data-aos="fade-left"
                  data-aos-duration="2000">

                  <img src={divider} style={{ minWidth: '100vw', minHeight: '100%' }} />
                </Item>
              </div>
            </Container>

            <Container id='slider'
              fluid
              style={{
                marginTop: '-1px',
                paddingTop: '5vw',
                paddingBottom: 0,
                color: '#7f8aa9ff',
                backgroundColor: '#7f8aa9ff',
              }}
              className='text-center'
            >
              <Mempelai>
                <h1 data-aos="fade-left"
                  data-aos-duration="2000">
                  Our Happinnes</h1>
              </Mempelai>
              <Slider slide={slider} />
              <div style={{ padding: '20px' }}>
                <Item>
                  <div className='col-12 col-md-6 mt-4' data-aos="fade-left"
                    data-aos-duration="2000"
                    style={{ backgroundColor: '#fff', borderRadius: '1rem' }}>
                    <Mempelai>
                      <p className='pt-4 pb-0 send' style={{ fontSize: '2rem', lineHeight: 0.7 }} >
                        Send Your Wishes
                </p>
                    </Mempelai>
                    <Col xs={12} className='bg-white rounded poppins pb-4' id='form'>
                      <form className="col-12 w-100 jefri" style={{ fontSize: '14px' }}>
                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                        <Item>
                          <Col xs={6} onClick={() => {
                            this.setState({ hadir: true })
                          }
                          }>
                            <input id="option" type="radio" checked={hadir ? true : false} />
                            <label ><span><span></span></span>Hadir</label>

                          </Col>
                          <Col xs={6} onClick={() => {
                            this.setState({ hadir: false })
                          }
                          }>
                            <input id="option" type="radio" checked={hadir ? false : true} />
                            <label ><span><span></span></span>Tidak Hadir</label>

                          </Col>
                        </Item>
                        <input ref={this.withfriend} type='text' className="col-12 w-100 text-center" placeholder="Jumlah orang yang akan hadir ..." name='with' />

                        <Item>
                          <Col xs={12} className=''>
                            {
                              submitted == true ? (
                                <Alert variant='success' style={{ fontSize: '14px' }}>
                                  Pesan anda sudah disampaikan
                                </Alert>) : (submitted === false ? (
                                  <Alert variant='danger' style={{ fontSize: '14px' }}>
                                    {
                                      err.map(val => {
                                        return (
                                          <li>{val}</li>
                                        )
                                      })
                                    }

                                  </Alert>
                                ) : false)
                            }

                          </Col>
                        </Item>
                        <Item>
                          <div className='col-6 button rounded btn p-0' 
                          onClick={() => this.handleSubmit()}> Kirim </div>
                        </Item>
                      </form>

                    </Col>
                  </div>
                </Item>
                <Item>
                  <h1 className='sacramento c-main f-small send pt-3 pb-3'>
                    Ririn & Rozaq
                                    </h1>
                </Item>
              </div>
            </Container>

            <Container
              className='pb-4 pt-4'
              id='footer'
              fluid
              style={{ backgroundColor: '#E1E7F6' }}>
              <Item ><p className='col-12 col-md-8 m-0 mt-2 text-center'
                style={{ color: '#7f8aa9ff', marginTop: '50px' }}>
                Possible Wedding
            </p></Item>
              <Item><p className='col-12 col-md-8 m-0 text-center'
                style={{ color: '#7f8aa9ff', fontStyle: 'italic', fontWeight: '600', marginTop: '50px' }}>
                Digital Invitation
            </p></Item>
              <Item>
                <div className='col-3 col-lg-1 p-md-4' 
                  onClick={() => {
                    window.open('http://instagram.com/possiblewedding')
                  }}>
                  <img src={logoig} className='img-fluid w-100 btn p-0'
                  />
                </div>
              </Item>
            </Container>
          </div>
        </div>
      </>


    )
  }
}